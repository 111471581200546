import React from 'react'

const Schedule = () => {
  return (
  <>
    <div className='text-2xl font-semibold font-sans text-[#2F345A]  m-5'>Schedule</div>
  </>
  )
}

export default Schedule