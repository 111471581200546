import React from 'react'

const Department = () => {
  return (
    <>
        <div className='text-2xl font-semibold font-sans text-[#2F345A]  m-5'>Department</div>
    </>
  )
}

export default Department