import React from 'react'

const Employee = () => {
  return (
      <>
        <div className='text-2xl font-semibold font-sans text-[#2F345A]  m-5'>Employees</div>
      </>
  )
}

export default Employee